<template>
  <li
    v-editable="blok"
    :style="{ ...customTheme }"
    :data-page-section-theme="pageSectionTheme"
    :data-is-community="!!communityContent"
    class="c-statistic flex flex-col after:block after:border-b after:opacity-10 after:content-['']"
  >
    <div class="flex items-center gap-6 py-4">
      <div class="c-statistic__value flex w-full max-w-28 flex-grow items-end">
        <div class="text-2xl font-medium leading-none">
          {{ blok.value }}
        </div>

        <div class="text-lg">
          {{ blok.units }}
        </div>
      </div>

      <div
        class="c-statistic__description lg:text-md flex items-center gap-2 lg:leading-tight"
      >
        <figure v-if="blok.customIcon || blok.icon">
          <img
            v-if="blok.customIcon?.filename"
            :src="blok.customIcon.filename"
            :alt="blok.customIcon.alt"
            class="w-6"
          />

          <UiIcon v-else-if="blok.icon" :icon="icon" size="md" />
        </figure>

        {{ blok.description }}
      </div>
    </div>
  </li>
</template>

<script setup lang="ts">
import type {
  BlockStatisticsGridStatisticStoryblok,
  PageSectionStoryblok,
} from '@/types/storyblok'
import type { Icon } from '@/components/ui/UiIcon.vue'

const props = defineProps<{
  blok: BlockStatisticsGridStatisticStoryblok
  pageSectionTheme: PageSectionStoryblok['theme']
}>()

const icon = computed(() => props.blok.icon as Icon)
const { communityContent } = storeToRefs(useCurrentStoryStore())

const customTheme = computed(() => {
  if (!communityContent.value) {
    return
  }

  return {
    '--custom-theme-value-color':
      props.pageSectionTheme === 'dark'
        ? communityContent.value.contentLight.color
        : communityContent.value.contentDark.color,
    '--custom-theme-text-color':
      props.pageSectionTheme === 'dark'
        ? communityContent.value.contentDark.color
        : communityContent.value.contentLight.color,
    '--custom-theme-border-color':
      props.pageSectionTheme === 'dark'
        ? communityContent.value.contentLight.color
        : communityContent.value.contentDark.color,
  } as StatisticCustomTheme
})

export interface StatisticCustomTheme {
  '--custom-theme-value-color': string
  '--custom-theme-text-color': string
  '--custom-theme-border-color': string
}
</script>

<style scoped lang="postcss">
.c-statistic {
  &[data-page-section-theme='dark'] {
    .c-statistic__value,
    .c-statistic__description {
      @apply text-white;
    }
  }

  &[data-page-section-theme='light'],
  &[data-page-section-theme='white'],
  &[data-page-section-theme='muted'] {
    @apply after:border-black;

    .c-statistic__value {
      @apply text-primary-500;
    }

    .c-statistic__description {
      @apply text-secondary;
    }
  }

  &[data-is-community='true'] {
    .c-statistic__value {
      color: var(--custom-theme-value-color, theme('colors.primary.500'));
    }

    .c-statistic__description {
      color: var(--custom-theme-text-color, theme('colors.secondary'));
    }

    &::after {
      border-color: var(--custom-theme-border-color, theme('colors.black'));
    }
  }
}
</style>
